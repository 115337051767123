import axios from "axios";
import React from "react";


import './App.css';



const ABOUT_TEXT = () => {
  return(
  <div className="simple-font">
  <div>Made by <a href = "https://twitter.com/Catalin04712177"> @Catalin</a>, inspired by <a href = "https://twitter.com/karpathy">@Karphaty</a></div>
  <div>• Made from 36927 films - there might be duplicates, I've seen them after everything was done x).</div>
  <div>• Data taken from wikipedia list of films -  alphabetical pages.</div>
  <div>• TFIDF embeddings with kNN cosine similarity (40 or less)</div>
  </div>
  );
}



function App() {
  const [filmList, setFilmList] = React.useState(null);
  const [isVisible, setIsVisible] = React.useState(null);
  const [rankType, setRankType] = React.useState('MOST_COMMON');
  const [searchText, setSearchText] = React.useState('');
  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/rand`).then((response) => {
      setFilmList(response.data);
 
      
    });
  }, []);

    const get_film_list = (id) => {
      setFilmList(null)
      let reverse = 'No'
      if (rankType === 'LEAST_COMMON') {
        reverse = 'Yes'
      }
      axios.get(`${process.env.REACT_APP_BASE_URL}/ranks?idx=${id}&reverse=${reverse}`).then((response) => {
        setFilmList(response.data);

        
      });
      window.scrollTo(0, 0);

    }


  const change_rank_types = () => {
    if (rankType === 'MOST_COMMON') {
      setRankType('LEAST_COMMON')
    }
    else {
      setRankType('MOST_COMMON')
    }
        

  }


  const get_image_id = (id) => {
    let img_idx = '';
    let needed_prefix = 8 - id.length;
    for (let i = 0; i < needed_prefix; i++) {
      img_idx+= '0';
    }
    img_idx+=id;
    return img_idx + '.jpg'
  }

  

  const about_gif_content = (isStyle) => {
    if (isStyle) {
      if (!isVisible) {
        return {}
      }
      return {
        height: "200px",
        backgroundColor: "#21212f",
        borderWidth: "5px",
        borderColor: "#289855",
        borderStyle: "solid",
        color: "white"
      }
    }
      else {
        if (isVisible)
          return <ABOUT_TEXT/>
        else
          return ""
      }
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const onClickUrl = (url) => {
    return () => openInNewTab(url)
  }
  

  const search_for_films = (e) => {
    if ((e.key === 'Enter') || (e.key === 'CLICKED_SEARCH') ) {
      setFilmList(null)
      axios.get(`${process.env.REACT_APP_BASE_URL}/find?title=${searchText}`).then((response) => {
              setFilmList(response.data);
            });
      window.scrollTo(0, 0);
      setSearchText('')
    } 
}

const on_search_text_change = (e) => {
  e.preventDefault();
  setSearchText(e.target.value);


}

function get_list_of_films() {
  if (!filmList) {
    return <div style={{width:"auto", height:"400px", textAlign: "center"}}><img alt = {'sloading'} style= {{width: "80px", height: "80px", textAlign: "center", padding: "100px"}} src = {process.env.PUBLIC_URL + "/loading_g.gif"}></img></div>
  }
  else {
  return (<div className= "grid">
    {filmList && filmList.map((film) => (
    <div className= "item"  key = {film.id}> 
      <div>
          <img
            className="film-container"
            onClick = { () => {get_film_list(film.id)} }
            src={process.env.REACT_APP_IMAGES_URL + 'static/' + get_image_id(film.id)} 
            alt={'Photo of ' + film.title}
          />
          <h3 className= "film-title" id = "text" onClick={onClickUrl(process.env.REACT_APP_WIKI_URL + film.href)}>{film.title}</h3>
        </div>
      </div>
    ))} 
    </div>)
  }
} //SE ADAUGA PREA MULTE RESOURCE PE PAGINA DACA SCHIMBI PAGINILE -  TREBUIE SCHIMBATA LOGICA - POATE O PAGINA NOUA

  return (
    <div className="container" >
      <div className="about-container">
      <span>
      <img alt = {'holoskull1'} onClick = {() => {setIsVisible(!isVisible)}} src = {process.env.PUBLIC_URL + "/holoskull.gif"}></img>
      <img alt = {'knight_g'} style= {{width: "80px", height: "80px"}} onClick = {() => {setIsVisible(!isVisible)}} src = {process.env.PUBLIC_URL + "/knight_g.gif"}></img>
      </span>
        <h1 className="thanks-text">Find something like that, but not really like that</h1>
      <span style={{float:"right"}}>
      <img alt = {'bear_g'}style= {{width: "80px", height: "80px"}} onClick = {() => {setIsVisible(!isVisible)}} src = {process.env.PUBLIC_URL + "/bear_g.gif"}></img>
      <img alt = {'holoskull2'} onClick = {() => {setIsVisible(!isVisible)}} src = {process.env.PUBLIC_URL + "/holoskull.gif"}></img>
      </span>
      </div>
      <div style={{display: "flex"}}>
      </div>
      <header className="header-container">
          {/* Search bar */}
            <div className="search-container">  
                <input type="text" value = {searchText} placeholder="Look for movie..." className="search-bar" onChange={ e => on_search_text_change(e)} onKeyUp = { event =>  search_for_films(event)}/> 
                <button onClick={() => {search_for_films({"key": "CLICKED_SEARCH"})}}>Search..</button>
                <button onClick={() => change_rank_types()}>{rankType}</button>
            </div>
    </header>
      <div style={about_gif_content(true)}>{about_gif_content(false)}</div>
        {get_list_of_films()}
    </div>
  );
} 

export default App;



